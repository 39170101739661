import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../redux/axios";
import icon from "./view-avatar.png";

const PlayerPointsStatusView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const paramsData = JSON.parse(id);
  const socreCardFecthData = () => {
    const data = {
      //   key: key,
    };
    Axios.post("/player-states", data)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socreCardFecthData();
  }, []);

  useEffect(() => {
    setInterval(socreCardFecthData, 1000);

    return () => {
      clearInterval(socreCardFecthData);
    };
  }, []);

  const filterData =
    data && data.find((data) => data.player_id === paramsData.id);

  return (
    <>
      <div className="player-points-view">
        <img
          src={
            filterData?.image ===
            "https://d2rpqczddzfs7n.cloudfront.net/uploads/players/"
              ? icon
              : filterData?.image
          }
          alt=""
          className="player-points-view-img"
          style={{ height: "70px", width: "70px" }}
        />
        <div className="player-points-view-details">
          <h6>{filterData?.player_name}</h6>
          <span>
            {filterData?.short_name}-{filterData?.role}
          </span>
          <div className="player-points-view-flex">
            <div className="player-points-view-p">
              <p>Selected by</p>
              <h5>{filterData?.selected_by}</h5>
            </div>
            <div className="player-points-view-p">
              <p>Credit</p>
              <h5>{filterData?.credit}</h5>
            </div>
          </div>
          {filterData?.isSelected === 1 ? (
            <p className="player-points-view-status">
              In your team{" "}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                  fill="#318B16"
                />
                <path
                  d="M6.18018 9.7073C6.14701 9.74058 6.1076 9.76699 6.06421 9.78501C6.02081 9.80303 5.97429 9.8123 5.9273 9.8123C5.88032 9.8123 5.83379 9.80303 5.7904 9.78501C5.747 9.76699 5.70759 9.74058 5.67443 9.7073L3.65755 7.6878C3.60776 7.63807 3.56826 7.57901 3.54131 7.514C3.51436 7.44899 3.50049 7.3793 3.50049 7.30893C3.50049 7.23855 3.51436 7.16887 3.54131 7.10386C3.56826 7.03885 3.60776 6.97979 3.65755 6.93005L3.91043 6.67718C3.96016 6.62739 4.01922 6.58789 4.08423 6.56094C4.14924 6.53398 4.21893 6.52011 4.2893 6.52011C4.35968 6.52011 4.42936 6.53398 4.49437 6.56094C4.55938 6.58789 4.61844 6.62739 4.66818 6.67718L5.92818 7.93718L9.33193 4.53255C9.38166 4.48276 9.44072 4.44326 9.50573 4.41631C9.57074 4.38936 9.64043 4.37549 9.7108 4.37549C9.78118 4.37549 9.85086 4.38936 9.91587 4.41631C9.98088 4.44326 10.0399 4.48276 10.0897 4.53255L10.3426 4.78543C10.3923 4.83516 10.4318 4.89422 10.4588 4.95923C10.4857 5.02424 10.4996 5.09393 10.4996 5.1643C10.4996 5.23468 10.4857 5.30436 10.4588 5.36937C10.4318 5.43438 10.3923 5.49344 10.3426 5.54318L6.18018 9.7073Z"
                  fill="white"
                />
              </svg>
            </p>
          ) : (
            <p className="player-points-view-status">
              Not in your team{" "}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                  fill="#888888"
                />
                <path
                  d="M6.18018 9.7073C6.14701 9.74058 6.1076 9.76699 6.06421 9.78501C6.02081 9.80303 5.97429 9.8123 5.9273 9.8123C5.88032 9.8123 5.83379 9.80303 5.7904 9.78501C5.747 9.76699 5.70759 9.74058 5.67443 9.7073L3.65755 7.6878C3.60776 7.63807 3.56826 7.57901 3.54131 7.514C3.51436 7.44899 3.50049 7.3793 3.50049 7.30893C3.50049 7.23855 3.51436 7.16887 3.54131 7.10386C3.56826 7.03885 3.60776 6.97979 3.65755 6.93005L3.91043 6.67718C3.96016 6.62739 4.01922 6.58789 4.08423 6.56094C4.14924 6.53398 4.21893 6.52011 4.2893 6.52011C4.35968 6.52011 4.42936 6.53398 4.49437 6.56094C4.55938 6.58789 4.61844 6.62739 4.66818 6.67718L5.92818 7.93718L9.33193 4.53255C9.38166 4.48276 9.44072 4.44326 9.50573 4.41631C9.57074 4.38936 9.64043 4.37549 9.7108 4.37549C9.78118 4.37549 9.85086 4.38936 9.91587 4.41631C9.98088 4.44326 10.0399 4.48276 10.0897 4.53255L10.3426 4.78543C10.3923 4.83516 10.4318 4.89422 10.4588 4.95923C10.4857 5.02424 10.4996 5.09393 10.4996 5.1643C10.4996 5.23468 10.4857 5.30436 10.4588 5.36937C10.4318 5.43438 10.3923 5.49344 10.3426 5.54318L6.18018 9.7073Z"
                  fill="white"
                />
              </svg>
            </p>
          )}
        </div>
      </div>
      <table className="table player-points-view-table">
        <thead>
          <tr>
            <th>Event</th>
            <th>Actual</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Announced/Sub</td>
            <td>Yes</td>
            <td>4</td>
          </tr>
          <tr>
            <td>Runs</td>
            <td>{filterData?.runs}</td>
            <td>{filterData?.actual_runs}</td>
          </tr>
          <tr>
            <td>4's</td>
            <td>{filterData?.fours}</td>
            <td>{filterData?.actual_fours}</td>
          </tr>
          <tr>
            <td>6's</td>
            <td>{filterData?.sixs}</td>
            <td>{filterData?.actual_sixs}</td>
          </tr>
          <tr>
            <td>S/R</td>
            <td>{filterData?.strike_rate}</td>
            <td>{filterData?.actual_strike_rate}</td>
          </tr>
          <tr>
            <td>30/50/100</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Duck</td>
            <td>{filterData?.duck}</td>
            <td>{filterData?.actual_duck}</td>
          </tr>
          <tr>
            <td>Wickets</td>
            <td>{filterData?.wickets}</td>
            <td>{filterData?.actual_wickets}</td>
          </tr>
          <tr>
            <td>E/R</td>
            <td>{filterData?.economy_rate}</td>
            <td>{filterData?.actual_economy_rate}</td>
          </tr>
          <tr>
            <td>Maiden Over</td>
            <td>{filterData?.maidens}</td>
            <td>{filterData?.actual_maidens}</td>
          </tr>
          <tr>
            <td>Run Out</td>
            <td>{filterData?.runouts}</td>
            <td>{filterData?.actual_runouts}</td>
          </tr>
          <tr>
            <td>Runout Thrower</td>
            <td>{filterData?.runout_thrower}</td>
            <td>{filterData?.actual_runout_thrower}</td>
          </tr>
          <tr>
            <td>Runout Catcher</td>
            <td>{filterData?.runout_catcher}</td>
            <td>{filterData?.actual_runout_catcher}</td>
          </tr>
          <tr>
            <td>Catch</td>
            <td>{filterData?.catch_points}</td>
            <td>{filterData?.actual_catch_points}</td>
          </tr>
          <tr>
            <td>Stumping</td>
            <td>{filterData?.stumping}</td>
            <td>{filterData?.actual_stumping}</td>
          </tr>
          <tr>
            <td>LBW/Bowled Bonus</td>
            <td>{filterData?.lbw_bowled}</td>
            <td>{filterData?.actual_lbw_bowled}</td>
          </tr>
          <tr>
            <td>Bonus Points</td>
            <td>{filterData?.bonus === 0 ? "No" : "Yes"}</td>
            <td>{filterData?.bonus}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td></td>
            <td>{filterData?.total_points}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PlayerPointsStatusView;
