import React, { useState } from "react";
import SecondInning from "./CommentryScreen/SecondInning";
import ThirdInning from "./CommentryScreen/ThirdInning";
import FourInning from "./CommentryScreen/FourInning";
import FirstInning from "./CommentryScreen/FirstInning";

const Commentry = ({ socket }) => {
  const [showFirstCommentary, setShowFirstCommentary] = useState(false);
  const [showSecondCommentary, setShowSecondCommentary] = useState(false);
  const [showThreeCommentary, setShowThreeCommentary] = useState(false);

  return (
    <>
      <div className="game-status">
        {/* <p className="game-status-p">
          {dataScoreCard && dataScoreCard.status_note}
        </p> */}
        {/* <FourInning setShowThreeCommentary={setShowThreeCommentary} />
        {showThreeCommentary ? (
          <ThirdInning setShowSecondCommentary={setShowSecondCommentary} />
        ) : (
          <ThirdInning setShowSecondCommentary={setShowSecondCommentary} />
        )}
        {showSecondCommentary ? (
          <SecondInning setShowFirstCommentary={setShowFirstCommentary} />
        ) : (
          <SecondInning setShowFirstCommentary={setShowFirstCommentary} />
        )} */}
        {/* {showFirstCommentary ? (
          <FirstInning socket={socket} />
        ) : (
          )} */}
        {/* <SecondInning socket={socket} /> */}
        <FirstInning socket={socket} />
      </div>
    </>
  );
};

export default Commentry;
