import React from "react";

const ExtraAndTotalAndNotPlay = ({ result }) => {
  return (
    <>
      <div className="">
        <div className="extra-run">
          <div className="extra-run-show">
            <p>EXTRAS</p>
          </div>
          <div className="extra-run-total">
            <p> {result?.extra_runs?.total}</p>
            <span>
              (nb {result?.extra_runs?.noballs},wd {result?.extra_runs?.wides},b
              {result?.extra_runs?.byes},lb {result?.extra_runs?.legbyes},pen{" "}
              {result?.extra_runs?.penalty ? result?.extra_runs?.penalty : 0})
            </span>
          </div>
        </div>
        <div className="total-wicket">
          <div className="total-wicket-show">
            <p>Total</p>
          </div>
          <div className="total-wicket-total">
            <p>{`${result?.equations?.runs}-${result?.equations?.wickets} (${result?.equations?.overs})`}</p>
            <span>CRR {parseFloat(result?.equations?.runrate)}</span>
          </div>
        </div>
        <div className="not-out">
          <p>YET TO BAT</p>
          {result &&
            result.did_not_bat &&
            result.did_not_bat.map((result, i) => (
              <span key={i}>{result.name}, </span>
            ))}
        </div>
      </div>
    </>
  );
};

export default ExtraAndTotalAndNotPlay;
