import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Axios from "../redux/axios";
import Loader from "./Loader/Loader";
import PlayerPoints from "./PlayerPoints";

const ScoreCard = ({ socket }) => {
  const location = useLocation();
  const { key } = useParams();
  const [show, setShow] = useState();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(false);
  const [gameStatus, setGameStatus] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const data = {
      match_key: key,
      match_status:
        localStorage.getItem("game_status") === "Live"
          ? 1
          : localStorage.getItem("game_status") === null
          ? 1
          : 2,
    };
    socket.emit("match-scorecard", data);
  }, [location]);

  useEffect(() => {
    setLoader(true);
    socket.on("scorecard-result", (res) => {
      if (res?.score && !sessionStorage.getItem("tab-open")) {
        setShow(res?.score[res?.latest_inning_number - 1]?.batting_team_id);
      }
      setLoader(false);
      if (res.score) {
        setGameStatus(res?.status);
        setStatus("match_key_not_found" === res?.status);
        localStorage.setItem("game_status", res?.status);
        if (
          (res?.score &&
            parseFloat(localStorage.getItem("last_score")) <=
              res?.score.slice(-1)[0].equations.overs) ||
          localStorage.getItem("last_score") === null
        ) {
          setData(res?.score);
        }
        localStorage.setItem(
          "last_score",
          res?.score && res?.score?.slice(-1)[0]?.equations?.overs
        );
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  // useEffect(() => {
  //   socket.emit("match-key", key);
  // }, []);

  // useEffect(() => {
  //   socket.on("score-card", (data) => {
  //     if (parseFloat(key) === data?.match_id) {
  //       setData(data);
  //       if (data.innings && !sessionStorage.getItem("tab-open")) {
  //         setShow(data.innings[data.latest_inning_number - 1].batting_team_id);
  //       }
  //     }
  //   });
  // }, []);

  // const scoreHandler = () => {
  //   const dataA = {
  //     key: key,
  //     limit: 1,
  //   };
  //   Axios.post("/score-card", dataA)
  //     .then((res) => {
  //       if (res?.data?.status === true) {
  //         setLoader(false);
  //         setStatus("match_key_not_found" === res?.data?.data);
  //         setStatus("Cancelled" === res?.data?.data?.status);
  //         setGameStatus(res?.data?.data?.status);
  //         localStorage.setItem("game_status", res?.data?.data?.status);
  //         if (
  //           (res?.data?.data?.score &&
  //             parseFloat(localStorage.getItem("last_score")) <=
  //               res?.data?.data?.score?.slice(-1)[0]?.equations?.overs) ||
  //           localStorage.getItem("last_score") === null
  //         ) {
  //           setData(res?.data?.data?.score);
  //         }
  //         localStorage.setItem(
  //           "last_score",
  //           res?.data?.data?.score &&
  //             res?.data?.data?.score?.slice(-1)[0]?.equations?.overs
  //         );
  //         if (res?.data?.data?.score && !sessionStorage.getItem("tab-open")) {
  //           setShow(
  //             res?.data?.data?.score[res?.data?.data?.latest_inning_number - 1]
  //               ?.batting_team_id
  //           );
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const socreCardFecthData = () => {
  //   if (
  //     localStorage.getItem("game_status") === "Completed" ||
  //     localStorage.getItem("game_status") === "Cancelled" ||
  //     localStorage.getItem("game_status") === "undefined"
  //   ) {
  //     return false;
  //   }

  //   const dataA = {
  //     key: key,
  //     limit: 1,
  //   };
  //   Axios.post("/score-card", dataA)
  //     .then((res) => {
  //       if (res?.data?.status === true) {
  //         setLoader(false);
  //         setStatus("match_key_not_found" === res?.data?.data);
  //         localStorage.setItem("game_status", res?.data?.data?.status);
  //         if (
  //           (res?.data?.data?.score &&
  //             parseFloat(localStorage.getItem("last_score")) <=
  //               res?.data?.data?.score.slice(-1)[0].equations.overs) ||
  //           localStorage.getItem("last_score") === null
  //         ) {
  //           setData(res?.data?.data?.score);
  //         }
  //         localStorage.setItem(
  //           "last_score",
  //           res?.data?.data?.score &&
  //             res?.data?.data?.score?.slice(-1)[0]?.equations?.overs
  //         );
  //         if (res?.data?.data?.score && !sessionStorage.getItem("tab-open")) {
  //           setShow(
  //             res?.data?.data?.score[res?.data?.data?.latest_inning_number - 1]
  //               ?.batting_team_id
  //           );
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   scoreHandler();
  //   setLoader(true);
  //   socreCardFecthData();
  //   localStorage.removeItem("last_score");
  //   localStorage.removeItem("game_status");
  // }, []);

  // useEffect(() => {
  //   setInterval(socreCardFecthData, 3000);

  //   return () => {
  //     clearInterval(socreCardFecthData);
  //   };
  // }, []);

  // useEffect(() => {
  //   sessionStorage.clear();
  // }, []);

  // useEffect(() => {
  //   socket.emit("match-data", key);
  // }, []);

  // useEffect(() => {
  //   socket.on("match-result", (res) => {
  //     console.log("res");
  //     console.log(res);
  //   });
  // }, []);

  // const matchDataUpdate = () => {
  //   // axios.get("http://localhost:3456/game").then((res) => {});
  //   socket.emit("match-data", key);
  //   console.log("emit done");
  // };

  // useEffect(() => {
  //   matchDataUpdate();
  //   // sessionStorage.removeItem("tab-open");
  // }, []);

  // useEffect(() => {
  //   setInterval(matchDataUpdate, 1000);
  //   return () => {
  //     clearInterval(matchDataUpdate);
  //   };
  // }, []);

  // useEffect(() => {
  //   socket.emit("match-player-point", 61328, 4790946);
  // }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="row">
          {data && data?.length ? (
            <div className="col-md-12">
              {status ? (
                <div className="text-center mt-3">
                  {gameStatus === "Cancelled"
                    ? "This match is canceled"
                    : " The game has begun, and the scorecard will appear shortly!"}
                </div>
              ) : (
                <div className="score-color">
                  <div className="accordion" id="accordionExample">
                    {data &&
                      data.map((result, i) => (
                        <div className="" key={i}>
                          <div
                            className={`score-color-flex ${
                              show === result.batting_team_id ? "" : "active"
                            } `}
                            onClick={() => {
                              show === result.batting_team_id
                                ? setShow("")
                                : setShow(result.batting_team_id);
                              sessionStorage.setItem("tab-open", true);
                            }}
                          >
                            <div className="score-color-flex-team">
                              <h4>{result.short_name}</h4>
                            </div>

                            <div className="score-color-flex-score">
                              <span>({result?.equations.overs} overs)</span>
                              <h4>
                                {result.equations.runs}/
                                {result.equations.wickets}
                              </h4>
                              {show === result.batting_team_id ? (
                                <i className="fa fa-angle-up"></i>
                              ) : (
                                <i className="fa fa-angle-down"></i>
                              )}
                            </div>
                          </div>
                          {parseFloat(show) ===
                            parseFloat(result.batting_team_id) && (
                            <PlayerPoints result={result} />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center mt-3">Game Score Not Available</div>
          )}
        </div>
      )}
    </>
  );
};

export default ScoreCard;
