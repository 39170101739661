import axios from "axios";

const Axios = axios.create({
  // baseURL: "https://scores-server.vision11.org",
  baseURL: "https://scores.vision11.org:3456/",
  // baseURL: "http://3.7.205.106:3456/",
  // baseURL: "http://localhost:3456/",
  // baseURL: "http://3.7.205.106:8070/",
});

export default Axios;
