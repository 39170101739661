import React from "react";

const FallOfWicket = ({ result }) => {
  return (
    <>
      <table className="table score-card-table">
        <thead>
          <tr>
            <th scope="col">Fall of Wickets</th>
            <th scope="col">Score</th>
            <th scope="col">Over</th>
          </tr>
        </thead>
        <tbody>
          {result &&
            result.fows &&
            result.fows.map((data, i) => (
              <tr key={i}>
                <td>{data.name}</td>
                <td>
                  {data.score_at_dismissal}/{data.number}
                </td>
                <td>{data.overs_at_dismissal}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default FallOfWicket;
