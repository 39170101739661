import React, { useState } from "react";
import Bolwer from "./Bolwer";
import ExtraAndTotalAndNotPlay from "./ExtraAndTotalAndNotPlay";
import FallOfWicket from "./FallOfWicket";

const PlayerPoints = ({ result }) => {
  return (
    <>
      <table className="table score-card-table">
        <thead>
          <tr className="change_batter">
            <th scope="col">Batter</th>
            <th scope="col">R</th>
            <th scope="col">B</th>
            <th scope="col">4s</th>
            <th scope="col">6s</th>
            <th scope="col">S/R</th>
          </tr>
        </thead>
        <tbody>
          {result &&
            result.batsmen &&
            result.batsmen.map((data, i) => (
              <tr key={i}>
                <td>
                  <p
                    className={`${
                      data.how_out === "Not out" && data.batting === "true"
                        ? "active"
                        : ""
                    }`}
                    // className={`${
                    //   data.batting === "true" && data.position === "striker"
                    //     ? "active"
                    //     : ""
                    // }`}
                  >
                    {data.name}
                    {data.batting === "true" &&
                    data.position === "striker" &&
                    data.how_out === "Not out" ? (
                      <b>*</b>
                    ) : (
                      ""
                    )}
                  </p>
                  <span
                    className={`${
                      data.batting === "true" && data.how_out === "Not out"
                        ? "active"
                        : ""
                    }`}
                  >
                    {data.batting === "true" && data.how_out === "Not out"
                      ? "Batting"
                      : data.how_out}
                  </span>
                </td>
                <td>
                  <h5>{data.runs}</h5>
                </td>
                <td>{data.balls_faced}</td>
                <td>{data.fours}</td>
                <td>{data.sixes}</td>
                <td>{parseFloat(data.strike_rate)}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <ExtraAndTotalAndNotPlay result={result} />
      <Bolwer result={result} />
      <FallOfWicket result={result} />
    </>
  );
};

export default PlayerPoints;
