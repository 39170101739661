import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../redux/axios";
import icon from "./avatar.png";

const PlayerPointsStatus = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [data, setData] = useState([]);

  const socreCardFecthData = () => {
    const data = {
      key: key,
    };
    Axios.post("/player-states", data)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socreCardFecthData();
  }, []);

  useEffect(() => {
    setInterval(socreCardFecthData, 1000);

    return () => {
      clearInterval(socreCardFecthData);
    };
  }, []);

  return (
    <>
      <table className="table player-state">
        <thead>
          <tr>
            <th scope="col">Players</th>
            <th scope="col">Points</th>
            <th scope="col">%SEL BY</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((data, i) => (
              <tr
                key={i}
                className={`${data.isSelected === 1 ? "active" : ""}`}
                onClick={() =>
                  navigate(
                    `/player-status-view/${JSON.stringify({
                      match_key: "61328",
                      user_id: "4790946",
                      id: data.player_id,
                    })}`
                  )
                }
              >
                <td>
                  <div className="player-state-div">
                    <img
                      src={
                        data.image ===
                        "https://d2rpqczddzfs7n.cloudfront.net/uploads/players/"
                          ? icon
                          : data.image
                      }
                      alt=""
                      style={{ height: "40px", width: "40px" }}
                    />
                    {data.isCaptain === 1 && (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="player-status"
                      >
                        <circle
                          cx="9.5"
                          cy="9.5"
                          r="9"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M10.9219 10.6855H12.1426C12.1035 11.151 11.9733 11.5661 11.752 11.9307C11.5306 12.292 11.2197 12.5768 10.8193 12.7852C10.4189 12.9935 9.93229 13.0977 9.35938 13.0977C8.91992 13.0977 8.52441 13.0195 8.17285 12.8633C7.82129 12.7038 7.52018 12.4792 7.26953 12.1895C7.01888 11.8965 6.82682 11.5433 6.69336 11.1299C6.56315 10.7165 6.49805 10.2542 6.49805 9.74316V9.15234C6.49805 8.64128 6.56478 8.17904 6.69824 7.76562C6.83496 7.35221 7.03027 6.99902 7.28418 6.70605C7.53809 6.40983 7.84245 6.18359 8.19727 6.02734C8.55534 5.87109 8.95736 5.79297 9.40332 5.79297C9.96973 5.79297 10.4482 5.89714 10.8389 6.10547C11.2295 6.3138 11.5322 6.60189 11.7471 6.96973C11.9652 7.33757 12.0986 7.75911 12.1475 8.23438H10.9268C10.8942 7.92839 10.8226 7.66634 10.7119 7.44824C10.6045 7.23014 10.445 7.06413 10.2334 6.9502C10.0218 6.83301 9.74512 6.77441 9.40332 6.77441C9.12337 6.77441 8.87923 6.8265 8.6709 6.93066C8.46257 7.03483 8.28841 7.18783 8.14844 7.38965C8.00846 7.59147 7.90267 7.84049 7.83105 8.13672C7.7627 8.42969 7.72852 8.76497 7.72852 9.14258V9.74316C7.72852 10.1012 7.75944 10.4268 7.82129 10.7197C7.88639 11.0094 7.98405 11.2585 8.11426 11.4668C8.24772 11.6751 8.41699 11.8363 8.62207 11.9502C8.82715 12.0641 9.07292 12.1211 9.35938 12.1211C9.70768 12.1211 9.98926 12.0658 10.2041 11.9551C10.4222 11.8444 10.5866 11.6833 10.6973 11.4717C10.8112 11.2568 10.8861 10.9948 10.9219 10.6855Z"
                          fill="black"
                        />
                      </svg>
                    )}
                    {data.isViceCaptain === 1 && (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="player-status"
                      >
                        <circle
                          cx="9.5"
                          cy="9.5"
                          r="9"
                          fill="white"
                          stroke="black"
                        />
                        <path
                          d="M6.15918 12.7012L8.0293 6.89062H9.38184L6.83301 14H5.91992L6.15918 12.7012ZM4.43066 6.89062L6.28613 12.7012L6.54004 14H5.62207L3.08301 6.89062H4.43066ZM14.3086 11.6855H15.5293C15.4902 12.151 15.36 12.5661 15.1387 12.9307C14.9173 13.292 14.6064 13.5768 14.2061 13.7852C13.8057 13.9935 13.319 14.0977 12.7461 14.0977C12.3066 14.0977 11.9111 14.0195 11.5596 13.8633C11.208 13.7038 10.9069 13.4792 10.6562 13.1895C10.4056 12.8965 10.2135 12.5433 10.0801 12.1299C9.94987 11.7165 9.88477 11.2542 9.88477 10.7432V10.1523C9.88477 9.64128 9.9515 9.17904 10.085 8.76562C10.2217 8.35221 10.417 7.99902 10.6709 7.70605C10.9248 7.40983 11.2292 7.18359 11.584 7.02734C11.9421 6.87109 12.3441 6.79297 12.79 6.79297C13.3564 6.79297 13.835 6.89714 14.2256 7.10547C14.6162 7.3138 14.9189 7.60189 15.1338 7.96973C15.3519 8.33757 15.4854 8.75911 15.5342 9.23438H14.3135C14.2809 8.92839 14.2093 8.66634 14.0986 8.44824C13.9912 8.23014 13.8317 8.06413 13.6201 7.9502C13.4085 7.83301 13.1318 7.77441 12.79 7.77441C12.5101 7.77441 12.266 7.8265 12.0576 7.93066C11.8493 8.03483 11.6751 8.18783 11.5352 8.38965C11.3952 8.59147 11.2894 8.84049 11.2178 9.13672C11.1494 9.42969 11.1152 9.76497 11.1152 10.1426V10.7432C11.1152 11.1012 11.1462 11.4268 11.208 11.7197C11.2731 12.0094 11.3708 12.2585 11.501 12.4668C11.6344 12.6751 11.8037 12.8363 12.0088 12.9502C12.2139 13.0641 12.4596 13.1211 12.7461 13.1211C13.0944 13.1211 13.376 13.0658 13.5908 12.9551C13.8089 12.8444 13.9733 12.6833 14.084 12.4717C14.1979 12.2568 14.2728 11.9948 14.3086 11.6855Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    <div className="">
                      <p>{data.player_name}</p>
                      <span>
                        {data.short_name} - {data.role}{" "}
                        {data.isSelected === 1 && (
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                              fill="#318B16"
                            />
                            <path
                              d="M6.18018 9.7073C6.14701 9.74058 6.1076 9.76699 6.06421 9.78501C6.02081 9.80303 5.97429 9.8123 5.9273 9.8123C5.88032 9.8123 5.83379 9.80303 5.7904 9.78501C5.747 9.76699 5.70759 9.74058 5.67443 9.7073L3.65755 7.6878C3.60776 7.63807 3.56826 7.57901 3.54131 7.514C3.51436 7.44899 3.50049 7.3793 3.50049 7.30893C3.50049 7.23855 3.51436 7.16887 3.54131 7.10386C3.56826 7.03885 3.60776 6.97979 3.65755 6.93005L3.91043 6.67718C3.96016 6.62739 4.01922 6.58789 4.08423 6.56094C4.14924 6.53398 4.21893 6.52011 4.2893 6.52011C4.35968 6.52011 4.42936 6.53398 4.49437 6.56094C4.55938 6.58789 4.61844 6.62739 4.66818 6.67718L5.92818 7.93718L9.33193 4.53255C9.38166 4.48276 9.44072 4.44326 9.50573 4.41631C9.57074 4.38936 9.64043 4.37549 9.7108 4.37549C9.78118 4.37549 9.85086 4.38936 9.91587 4.41631C9.98088 4.44326 10.0399 4.48276 10.0897 4.53255L10.3426 4.78543C10.3923 4.83516 10.4318 4.89422 10.4588 4.95923C10.4857 5.02424 10.4996 5.09393 10.4996 5.1643C10.4996 5.23468 10.4857 5.30436 10.4588 5.36937C10.4318 5.43438 10.3923 5.49344 10.3426 5.54318L6.18018 9.7073Z"
                              fill="white"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                  </div>
                </td>
                <td>{data.points}</td>
                <td>{data.selected_by}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default PlayerPointsStatus;
