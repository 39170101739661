import React, { useEffect, useState } from "react";
import Axios from "../../redux/axios";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import Loading from "../Loader/Loading";

const FirstInning = ({ socket }) => {
  const { key } = useParams();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const keys = {
      match_key: key,
      inning: 1,
      match_status: localStorage.getItem("commentary_status") === "3" ? 1 : 2,
      page: page,
    };
    socket.emit("commentary-data", keys);
  }, [page]);

  useEffect(() => {
    setLoader(true);
    socket.on("commentary-first", (req) => {
      console.log("emit done");
      localStorage.setItem("last_over1", req && req?.data[0]?.over);
      localStorage.setItem("last_ball1", req && req?.data[0]?.ball);
      localStorage.setItem("commentary_status", req?.betsData?.status);

      if (req) {
        if (parseFloat(req.status) === parseFloat(1)) {
          setData(req.data);
        } else {
          setData((prev) => [...prev, ...req.data]);
        }
      }
      setDataShow(req?.betsData);
      setLoading(false);
      setLoader(false);
      localStorage.setItem("commentary_status", req?.betsData?.status);
    });
  }, []);

  // async function fecthData() {
  //   const data = {  //     key: key,
  //     page: page,
  //   };
  //   await Axios.post("/commentary-first", data)
  //     .then(async (res) => {
  //       if (res) {
  //         if (res.data.status === true) {
  //           const resultdata = await res.data.data;
  //           localStorage.setItem(
  //             "last_over1",
  //             res.data.data && resultdata[0].over
  //           );
  //           localStorage.setItem(
  //             "last_ball1",
  //             res.data.data && resultdata[0].ball
  //           );
  //           localStorage.setItem(
  //             "commentary_status",
  //             res?.data?.betsData?.status
  //           );
  //           if (resultdata) {
  //             setData((prev) => [...prev, ...resultdata]);
  //           }
  //           setDataShow(res.data.betsData);
  //           setLoading(false);
  //           localStorage.setItem(
  //             "commentary_status",
  //             res?.data?.betsData?.status
  //           );
  //           setLoader(false);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // const socreCardFecthData = async () => {
  //   if (
  //     localStorage.getItem("commentary_status") === "2" ||
  //     localStorage.getItem("commentary_status") === "undefined"
  //   ) {
  //   } else {
  //     // if (document.documentElement.scrollTop === 0) {
  //     const data = {
  //       key: key,
  //     };
  //     await Axios.post("/commentary-first", data)
  //       .then(async (res) => {
  //         if (res) {
  //           if (res.data.status === true) {
  //             const resultdata = await res.data.data;
  //             const ballCheckLocal = parseFloat(
  //               localStorage.getItem("last_ball1")
  //             )
  //               ? parseFloat(localStorage.getItem("last_ball1"))
  //               : "0";
  //             const ballCheckLive = parseFloat(
  //               res.data.data && resultdata[0].ball
  //             )
  //               ? parseFloat(res.data.data && resultdata[0].ball)
  //               : "0";
  //             if (
  //               (parseFloat(localStorage.getItem("last_over1")) <=
  //                 parseFloat(res.data.data && resultdata[0].over) &&
  //                 ballCheckLocal <= ballCheckLive) ||
  //               localStorage.getItem("last_ball1") === null
  //             ) {
  //               setData(resultdata);
  //               setDataShow(res.data.betsData);
  //             }
  //             localStorage.setItem(
  //               "last_over1",
  //               res.data.data && resultdata[0].over
  //             );
  //             localStorage.setItem(
  //               "last_ball1",
  //               res.data.data && resultdata[0].ball
  //             );
  //             localStorage.setItem(
  //               "commentary_status",
  //               res?.data?.betsData?.status
  //             );
  //             setLoader(false);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     // }
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("commentary_status") === "2" ||
  //     localStorage.getItem("commentary_status") === "undefined"
  //   ) {
  //     fecthData();
  //   }
  // }, [page]);

  // useEffect(() => {
  //   socreCardFecthData();
  //   setLoader(true);
  //   localStorage.removeItem("commentary_status");
  //   localStorage.removeItem("last_ball1");
  //   localStorage.removeItem("last_over1");
  // }, []);

  // useEffect(() => {
  //   setInterval(socreCardFecthData, 3000);
  //   return () => {
  //     clearInterval(socreCardFecthData);
  //   };
  // }, []);

  const handleScrolling = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
        setLoading(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling);
    return () => window.removeEventListener("scroll", handleScrolling);
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {data && data.length ? (
            <div className="commentry-style">
              {data &&
                data.map((result, i) => (
                  <div className="commentry-style-space" key={i}>
                    {loading ? (
                      <Loading />
                    ) : (
                      <div className="row">
                        {result.event === "overend" ? (
                          <div className="live-score-card">
                            <div className="live-score-card-ball">
                              <p>Over {result.over}</p>
                              {/* <div className="live-score-card-balls">
                            {byDataStorage &&
                              byDataStorage.map((data, i) => (
                                <span key={i}>{data}</span>
                              ))}
                          </div> */}
                            </div>
                            <div className="live-score-card-details">
                              <div className="live-score-card-score">
                                {/* <p>MLS</p> */}
                                <span>
                                  {dataShow && dataShow.short_name}{" "}
                                  {result.score}
                                </span>
                              </div>
                              <div className="live-score-card-betname">
                                <div className="" key={i}>
                                  {result?.bats
                                    ?.map((data) => {
                                      const batsName =
                                        dataShow &&
                                        dataShow?.batsmen?.find(
                                          (bat) =>
                                            parseFloat(bat.batsman_id) ===
                                            parseFloat(data.batsman_id)
                                        );
                                      if (
                                        parseFloat(batsName.batsman_id) ===
                                        parseFloat(data.batsman_id)
                                      ) {
                                        return { ...data, name: batsName.name };
                                      }
                                    })
                                    .map((data, i) => (
                                      <p key={i}>{data.name}</p>
                                    ))}
                                </div>
                                <p>
                                  {
                                    (result?.bowls?.map((data) => {
                                      const batsName =
                                        dataShow &&
                                        dataShow?.bowlers?.find(
                                          (bat) =>
                                            parseFloat(bat.bowler_id) ===
                                            parseFloat(data.bowler_id)
                                        );
                                      if (
                                        parseFloat(batsName.bowler_id) ===
                                        parseFloat(data.bowler_id)
                                      ) {
                                        return { ...data, name: batsName.name };
                                      }
                                    }))[0].name
                                  }
                                </p>
                              </div>
                              <div className="live-score-card-bets">
                                {result?.bats
                                  ?.map((data) => {
                                    const batsName =
                                      dataShow &&
                                      dataShow?.batsmen?.find(
                                        (bat) =>
                                          parseFloat(bat.batsman_id) ===
                                          parseFloat(data.batsman_id)
                                      );
                                    if (
                                      parseFloat(batsName.batsman_id) ===
                                      parseFloat(data.batsman_id)
                                    ) {
                                      return { ...data, name: batsName.name };
                                    }
                                  })
                                  .map((data, i) => (
                                    <p key={i}>
                                      {data.runs} ({data.balls_faced})
                                    </p>
                                  ))}

                                <div className="">
                                  <p>
                                    {
                                      (result?.bowls?.map((data) => {
                                        const batsName =
                                          dataShow &&
                                          dataShow?.bowlers?.find(
                                            (bat) =>
                                              parseFloat(bat.bowler_id) ===
                                              parseFloat(data.bowler_id)
                                          );
                                        if (
                                          parseFloat(batsName.bowler_id) ===
                                          parseFloat(data.bowler_id)
                                        ) {
                                          return {
                                            ...data,
                                            name: batsName.name,
                                          };
                                        }
                                      }))[0].runs_conceded
                                    }{" "}
                                    (
                                    {
                                      (result?.bowls?.map((data) => {
                                        const batsName =
                                          dataShow &&
                                          dataShow?.bowlers?.find(
                                            (bat) =>
                                              parseFloat(bat.bowler_id) ===
                                              parseFloat(data.bowler_id)
                                          );
                                        if (
                                          parseFloat(batsName.bowler_id) ===
                                          parseFloat(data.bowler_id)
                                        ) {
                                          return {
                                            ...data,
                                            name: batsName.name,
                                          };
                                        }
                                      }))[0].overs
                                    }
                                    )
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-md-2 col-2">
                              <div className="wicket_style">
                                <div className="wicket-show">
                                  <p
                                    className={`${
                                      result.score === "w"
                                        ? "red"
                                        : result.score === 4
                                        ? "yello"
                                        : result.score === 6
                                        ? "green"
                                        : ""
                                    }`}
                                  >
                                    {result.score}
                                  </p>
                                  <span>
                                    {result.over}.{result.ball}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-10 col-10">
                              <div className="commentry-screen">
                                <p className="commentry-screen-p">
                                  {result.commentary}
                                </p>
                                <span className="commentry-screen-span">
                                  {result.how_out}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default FirstInning;
