import React from "react";

const Bolwer = ({ result }) => {
  return (
    <>
      <table className="table score-card-table">
        <thead>
          <tr>
            <th scope="col">Bowler</th>
            <th scope="col">O</th>
            <th scope="col">M</th>
            <th scope="col">R</th>
            <th scope="col">W</th>
            <th scope="col">Eco</th>
          </tr>
        </thead>
        <tbody>
          {result &&
            result.bowlers &&
            result.bowlers.map((data, i) => (
              <tr key={i}>
                <td>
                  {data.name}{" "}
                  {data.bowling === "true" &&
                  data.position === "active bowler" ? (
                    <b style={{ fontWeight: "800" }}>*</b>
                  ) : (
                    ""
                  )}
                </td>
                <td>{data.overs}</td>
                <td>{data.maidens}</td>
                <td>{data.runs_conceded}</td>
                <td>
                  <h6>{data.wickets}</h6>
                </td>
                <td>{parseFloat(data.econ)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Bolwer;
