import React, { Suspense, useEffect } from "react";
import "./App.css";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import io from "socket.io-client";
import PlayerPointsStatus from "./Components/PlayerPointsStatus";
import PlayerPointsStatusView from "./Components/PlayerPointsStatusView";
import Loader from "./Components/Loader/Loader";
import ScoreCard from "./Components/ScoreCard";
import Commentry from "./Components/Commentry";
// const ScoreCard = React.lazy(() => import("./Components/ScoreCard"));
// const Commentry = React.lazy(() => import("./Components/Commentry"));

function App() {
  const ENDPT = "https://scores.vision11.org:3456/";
  // const ENDPT = "http://3.7.205.106:3456/";
  // const ENDPT = "http://localhost:3456/";
  // const ENDPT = "http://3.7.205.106:3456/";
  // const ENDPT = "http://score.focus11.net:2701";
  const socket = io(ENDPT, {
    transports: ["websocket"],
  });

  // useEffect(() => {
  //   const data = {
  //     match_key: "72052",
  //     match_status: 0,
  //   };
  //   socket.emit("match-data", data);
  // }, []);

  // useEffect(() => {
  //   socket.on("match-result", (res) => {
  //     console.log(res);
  //   });
  //   socket.on("ball-by-ball", (res) => {
  //     console.log(res);
  //   });
  // }, []);

  // const matchDataUpdate = () => {
  //   // axios.get("http://localhost:3456/game").then((res) => {});
  //   socket.emit("match-key", "61432");
  // };

  // useEffect(() => {
  //   matchDataUpdate();
  //   // sessionStorage.removeItem("tab-open");
  // }, []);

  // useEffect(() => {
  //   setInterval(matchDataUpdate, 10000);
  //   return () => {
  //     clearInterval(matchDataUpdate);
  //   };
  // }, []);

  // const scoreCardAddData = () => {
  //   axios
  //     .get("http://localhost:3456/score-store?matchkey=64097")
  //     .then((res) => {
  //       // console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   axios
  //     .get("http://localhost:3456/commentary-store?matchkey=64097&inning=1")
  //     .then((res) => {
  //       // console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   scoreCardAddData();
  // }, []);

  // useEffect(() => {
  //   setInterval(scoreCardAddData, 1000);
  //   return () => {
  //     clearInterval(scoreCardAddData);
  //   };
  // }, []);

  // const arrayList = [
  //   { key: "67167" },
  //   { key: "67249" },
  //   { key: "66697" },
  //   { key: "67136" },
  //   { key: "66333" },
  //   { key: "66403" },
  //   { key: "67156" },
  //   { key: "66650" },
  //   { key: "67135" },
  //   { key: "67253" },
  //   { key: "65162" },
  //   { key: "66402" },
  //   { key: "67133" },
  //   { key: "66711" },
  //   { key: "66649" },
  //   { key: "66514" },
  //   { key: "67132" },
  //   { key: "66710" },
  //   { key: "66783" },
  //   { key: "66782" },
  //   { key: "66513" },
  //   { key: "66781" },
  //   { key: "66780" },
  //   { key: "62998" },
  //   { key: "67248" },
  //   { key: "66696" },
  //   { key: "64583" },
  //   { key: "67155" },
  //   { key: "67245" },
  //   { key: "67247" },
  //   { key: "67246" },
  //   { key: "67244" },
  //   { key: "66826" },
  //   { key: "66695" },
  //   { key: "66688" },
  //   { key: "66878" },
  //   { key: "67154" },
  //   { key: "62997" },
  //   { key: "66401" },
  //   { key: "66648" },
  //   { key: "67161" },
  //   { key: "60208" },
  //   { key: "66825" },
  //   { key: "67160" },
  //   { key: "62383" },
  //   { key: "67151" },
  //   { key: "64645" },
  //   { key: "65708" },
  //   { key: "65563" },
  //   { key: "62382" },
  //   { key: "66400" },
  //   { key: "67159" },
  //   { key: "66709" },
  //   { key: "66647" },
  //   { key: "66512" },
  //   { key: "66565" },
  //   { key: "67158" },
  //   { key: "66762" },
  //   { key: "66708" },
  //   { key: "66511" },
  //   { key: "66761" },
  //   { key: "66877" },
  //   { key: "62996" },
  //   { key: "66434" },
  //   { key: "66876" },
  //   { key: "66875" },
  //   { key: "64582" },
  //   { key: "66691" },
  //   { key: "66646" },
  //   { key: "66872" },
  //   { key: "66874" },
  //   { key: "66871" },
  //   { key: "66824" },
  //   { key: "66873" },
  //   { key: "67157" },
  //   { key: "66645" },
  //   { key: "62995" },
  //   { key: "66399" },
  //   { key: "66945" },
  //   { key: "66823" },
  //   { key: "67153" },
  //   { key: "66944" },
  //   { key: "63105" },
  //   { key: "66707" },
  //   { key: "60217" },
  //   { key: "66398" },
  //   { key: "65562" },
  //   { key: "62381" },
  //   { key: "66943" },
  //   { key: "67150" },
  //   { key: "66510" },
  //   { key: "66942" },
  //   { key: "67152" },
  //   { key: "66760" },
  //   { key: "66706" },
  //   { key: "66759" },
  //   { key: "66509" },
  //   { key: "66758" },
  //   { key: "66999" },
  //   { key: "66757" },
  //   { key: "66690" },
  //   { key: "66689" },
  //   { key: "66694" },
  //   { key: "66644" },
  //   { key: "66693" },
  //   { key: "66293" },
  //   { key: "66929" },
  //   { key: "66332" },
  //   { key: "66397" },
  //   { key: "66643" },
  //   { key: "66636" },
  //   { key: "66928" },
  //   { key: "66985" },
  //   { key: "66680" },
  //   { key: "60207" },
  //   { key: "66396" },
  //   { key: "65161" },
  //   { key: "66508" },
  //   { key: "66342" },
  //   { key: "66927" },
  //   { key: "66705" },
  //   { key: "66635" },
  //   { key: "66838" },
  //   { key: "66679" },
  //   { key: "66926" },
  //   { key: "66984" },
  //   { key: "66704" },
  //   { key: "66756" },
  //   { key: "66507" },
  //   { key: "66594" },
  //   { key: "66593" },
  //   { key: "66755" },
  //   { key: "66868" },
  //   { key: "66867" },
  //   { key: "66592" },
  //   { key: "66591" },
  //   { key: "66692" },
  //   { key: "66642" },
  //   { key: "66822" },
  //   { key: "66570" },
  //   { key: "66395" },
  //   { key: "66624" },
  //   { key: "66330" },
  //   { key: "66641" },
  //   { key: "66331" },
  //   { key: "66634" },
  //   { key: "66623" },
  //   { key: "66821" },
  //   { key: "66869" },
  //   { key: "66622" },
  //   { key: "63104" },
  //   { key: "63026" },
  //   { key: "66630" },
  //   { key: "66394" },
  //   { key: "66703" },
  //   { key: "66621" },
  //   { key: "66633" },
  //   { key: "66837" },
  //   { key: "66506" },
  //   { key: "66620" },
  //   { key: "66702" },
  //   { key: "66701" },
  //   { key: "66870" },
  //   { key: "66505" },
  //   { key: "66699" },
  //   { key: "66590" },
  //   { key: "66700" },
  //   { key: "66794" },
  //   { key: "62993" },
  //   { key: "66589" },
  //   { key: "66588" },
  //   { key: "64581" },
  //   { key: "66640" },
  //   { key: "66820" },
  //   { key: "60216" },
  //   { key: "66619" },
  //   { key: "66393" },
  //   { key: "66639" },
  //   { key: "66330" },
  //   { key: "66632" },
  //   { key: "66618" },
  //   { key: "66819" },
  //   { key: "66629" },
  //   { key: "66678" },
  //   { key: "66617" },
  //   { key: "65561" },
  //   { key: "66392" },
  //   { key: "66616" },
  //   { key: "66600" },
  //   { key: "66631" },
  //   { key: "66504" },
  //   { key: "66677" },
  //   { key: "66615" },
  //   { key: "66628" },
  //   { key: "66544" },
  //   { key: "66543" },
  //   { key: "66599" },
  //   { key: "66503" },
  //   { key: "66540" },
  //   { key: "66541" },
  //   { key: "66542" },
  //   { key: "62994" },
  //   { key: "66569" },
  //   { key: "66638" },
  //   { key: "64580" },
  //   { key: "60206" },
  //   { key: "66568" },
  //   { key: "66614" },
  //   { key: "66391" },
  //   { key: "66637" },
  //   { key: "66328" },
  //   { key: "66613" },
  //   { key: "66627" },
  //   { key: "66676" },
  //   { key: "66675" },
  //   { key: "66612" },
  //   { key: "63025" },
  //   { key: "66390" },
  //   { key: "66329" },
  //   { key: "65560" },
  //   { key: "62379" },
  //   { key: "62376" },
  //   { key: "66611" },
  //   { key: "66598" },
  //   { key: "66502" },
  //   { key: "66674" },
  //   { key: "66673" },
  //   { key: "66610" },
  //   { key: "66626" },
  //   { key: "66597" },
  //   { key: "66681" },
  //   { key: "66501" },
  //   { key: "66587" },
  //   { key: "66586" },
  //   { key: "66584" },
  //   { key: "66585" },
  //   { key: "66686" },
  //   { key: "66567" },
  //   { key: "66684" },
  //   { key: "66685" },
  //   { key: "66683" },
  //   { key: "66667" },
  //   { key: "66292" },
  //   { key: "66566" },
  //   { key: "66609" },
  //   { key: "66671" },
  //   { key: "66670" },
  //   { key: "66669" },
  //   { key: "65160" },
  //   { key: "66389" },
  //   { key: "66326" },
  //   { key: "66608" },
  //   { key: "66625" },
  //   { key: "66607" },
  //   { key: "66687" },
  //   { key: "66596" },
  //   { key: "66388" },
  //   { key: "66341" },
  //   { key: "66327" },
  //   { key: "65559" },
  //   { key: "66606" },
  //   { key: "66500" },
  //   { key: "63024" },
  //   { key: "66605" },
  //   { key: "66538" },
  //   { key: "66595" },
  //   { key: "66539" },
  //   { key: "66499" },
  //   { key: "66433" },
  //   { key: "66583" },
  //   { key: "66582" },
  //   { key: "66537" },
  //   { key: "66536" },
  //   { key: "66535" },
  //   { key: "62992" },
  //   { key: "66581" },
  //   { key: "66580" },
  //   { key: "66668" },
  //   { key: "66665" },
  //   { key: "64579" },
  //   { key: "66666" },
  //   { key: "66663" },
  //   { key: "66664" },
  //   { key: "66291" },
  //   { key: "66486" },
  //   { key: "66662" },
  //   { key: "66661" },
  //   { key: "66660" },
  //   { key: "65159" },
  //   { key: "66659" },
  //   { key: "66387" },
  //   { key: "62991" },
  //   { key: "66324" },
  //   { key: "60205" },
  //   { key: "66485" },
  //   { key: "63103" },
  //   { key: "66656" },
  //   { key: "66655" },
  //   { key: "66484" },
  //   { key: "64644" },
  //   { key: "66386" },
  //   { key: "60803" },
  //   { key: "65558" },
  //   { key: "66325" },
  //   { key: "66483" },
  //   { key: "66559" },
  //   { key: "66498" },
  //   { key: "66654" },
  //   { key: "66653" },
  //   { key: "66482" },
  //   { key: "66534" },
  //   { key: "66533" },
  //   { key: "66497" },
  //   { key: "66277" },
  //   { key: "66532" },
  //   { key: "66531" },
  //   { key: "66530" },
  //   { key: "62990" },
  //   { key: "66276" },
  //   { key: "66658" },
  //   { key: "66657" },
  //   { key: "64578" },
  //   { key: "66355" },
  //   { key: "66360" },
  //   { key: "66563" },
  //   { key: "66290" },
  //   { key: "66604" },
  //   { key: "66481" },
  //   { key: "66603" },
  //   { key: "66602" },
  //   { key: "66601" },
  //   { key: "66385" },
  //   { key: "62989" },
  //   { key: "60215" },
  //   { key: "66480" },
  //   { key: "66574" },
  //   { key: "66573" },
  //   { key: "66479" },
  //   { key: "66384" },
  //   { key: "66340" },
  //   { key: "65557" },
  //   { key: "66478" },
  //   { key: "66496" },
  //   { key: "66572" },
  //   { key: "66571" },
  //   { key: "66477" },
  //   { key: "66450" },
  //   { key: "66495" },
  //   { key: "66275" },
  //   { key: "66274" },
  //   { key: "66579" },
  //   { key: "66449" },
  //   { key: "66273" },
  //   { key: "66272" },
  //   { key: "66557" },
  //   { key: "66578" },
  //   { key: "66558" },
  //   { key: "66577" },
  //   { key: "66576" },
  //   { key: "66564" },
  //   { key: "66575" },
  //   { key: "66289" },
  //   { key: "60204" },
  //   { key: "63102" },
  //   { key: "66476" },
  //   { key: "66562" },
  //   { key: "66561" },
  //   { key: "66560" },
  //   { key: "65158" },
  //   { key: "66383" },
  //   { key: "66288" },
  //   { key: "66475" },
  //   { key: "66474" },
  //   { key: "66473" },
  //   { key: "66382" },
  //   { key: "66494" },
  //   { key: "66472" },
  //   { key: "66529" },
  //   { key: "66528" },
  //   { key: "66527" },
  //   { key: "66457" },
  //   { key: "66493" },
  //   { key: "66556" },
  //   { key: "66555" },
  //   { key: "66526" },
  //   { key: "66525" },
  //   { key: "62988" },
  //   { key: "66553" },
  //   { key: "64577" },
  //   { key: "60214" },
  //   { key: "66287" },
  //   { key: "66322" },
  //   { key: "66471" },
  //   { key: "66381" },
  //   { key: "66554" },
  //   { key: "66221" },
  //   { key: "66470" },
  //   { key: "66303" },
  //   { key: "66469" },
  //   { key: "66380" },
  //   { key: "65556" },
  //   { key: "66339" },
  //   { key: "66323" },
  //   { key: "66468" },
  //   { key: "66492" },
  //   { key: "66302" },
  //   { key: "66467" },
  //   { key: "66524" },
  //   { key: "66523" },
  //   { key: "66491" },
  //   { key: "66522" },
  //   { key: "66521" },
  //   { key: "66520" },
  //   { key: "62987" },
  //   { key: "66286" },
  //   { key: "60203" },
  //   { key: "63101" },
  //   { key: "66466" },
  //   { key: "66320" },
  //   { key: "66379" },
  //   { key: "66465" },
  //   { key: "66220" },
  //   { key: "66464" },
  //   { key: "66301" },
  //   { key: "66463" },
  //   { key: "66321" },
  //   { key: "65555" },
  //   { key: "66490" },
  //   { key: "66270" },
  //   { key: "66300" },
  //   { key: "66462" },
  //   { key: "66456" },
  //   { key: "66489" },
  //   { key: "66519" },
  //   { key: "66285" },
  //   { key: "66461" },
  //   { key: "66318" },
  //   { key: "66219" },
  //   { key: "66460" },
  //   { key: "65691" },
  //   { key: "65929" },
  //   { key: "66459" },
  //   { key: "65693" },
  //   { key: "66488" },
  //   { key: "66319" },
  //   { key: "66458" },
  //   { key: "66444" },
  // ];
  // console.log(arrayList.length);
  // useEffect(() => {
  //   for (let i = 0; i <= arrayList.length; i++) {
  //     setTimeout(() => {
  //       if (arrayList[i]) {
  //         // const params = new URLSearchParams({
  //         //   matchkey: `${arrayList[i]?.key}`,
  //         // }).toString();

  //         axios
  //           .get(
  //             "https://scores.vision11.org:3456/score-store?matchkey=" +
  //               `${arrayList[i]?.key}`
  //           )
  //           .then((res) => {
  //             console.log(res);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //     }, 100 * i);
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <div className="App">
        <Routes>
          {/* <Route
            path="/"
            element={
              <>
                <div className="">Hello World</div>
              </>
            }
          /> */}
          <Route
            path="/new-scorecard/:key/1"
            element={<ScoreCard socket={socket} />}
          />
          <Route
            path="/new-commentary/:key/1"
            element={<Commentry socket={socket} />}
          />
          <Route path="/player-status" element={<PlayerPointsStatus />} />
          <Route
            path="/player-status-view/:id"
            element={<PlayerPointsStatusView />}
          />
          {/* <Route path="/score/:key/1" element={<Score socket={socket} />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
